import { listenGlobalEvent, removeGlobalEvent } from '~/utils/event-handler';

export default {
  mounted() {
    listenGlobalEvent('success-search', (data) => {
      const route = `variants/${data.detail.uid}?search=${data.detail.search}`;
      this.goToAffSeparatePage(route);
    });
    listenGlobalEvent('success-return', (data) => {
      const {params, query} = data.detail;
      const route = `return-create/${params.id}?params=${query.params}&pass=${query.pass}`;
      this.goToAffSeparatePage(route);
    });
    listenGlobalEvent('success-order', (data) => {
      const {params, query} = data.detail;
      const route = `order/${params.id}?sign=${query.sign}`;
      this.goToAffSeparatePage(route);
    });
  },
  methods: {
    goToAffSeparatePage(route) {
      window.location.href = `${window.location.origin}/flights#/${route}`;
    },
  },
  beforeDestoy() {
    removeGlobalEvent('success-search');
    removeGlobalEvent('success-return');
    removeGlobalEvent('success-order');
  },
};