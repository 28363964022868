//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'check-ticket-icon',
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
