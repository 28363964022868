//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkTicketIcon from '~/components/icons/check-ticket-icon';
import returnTicketIcon from '~/components/icons/return-ticket-icon';
import passportIcon from '~/components/icons/passport-icon';
import {sendGlobalEvent} from '~/utils/event-handler';

export default {
  name: 'return-block',
  components: {
    checkTicketIcon,
    returnTicketIcon,
    passportIcon,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttons: [
        {label: 'Проверка билета', icon: 'check-ticket-icon', action: 'open-find'},
        {label: 'Возврат/обмен билета', icon: 'return-ticket-icon', action: 'open-return'},
        {label: 'Изменение паспортных данных', icon: 'passport-icon', action: 'open-exchange-pass'},
      ],
    };
  },
  methods: {
    action(action) {
      sendGlobalEvent(action);
    },
  },
};
